import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Element from 'styles/Element';
import Odometer from 'components/Odometer';
import extractElementStyles from 'utils/extractElementStyles';

import { DayOdometerWrapper, Days } from './Days';
import EndFireworks from './EndFireworks';

const ANIMATION_DURATION = 1000;

const OdometerWrapper = styled(Element)`
  display: flex !important;
  align-items: center;
  gap: 15px;

  .odometer.odometer-theme-default.odometer-animating {
    .odometer-ribbon-inner {
      transition-duration: ${({ isAnimated }) =>
        isAnimated ? ANIMATION_DURATION : 0}ms !important;
    }
  }
`;

const fillZero = number => (number < 10 ? `0${number}` : number);

/**
 * @param {Date} time
 * @returns {string}
 */
const getDiff = time => {
  const dateFuture = time;
  const dateNow = new Date();

  if (dateNow > dateFuture) {
    return '0';
  }

  let seconds = Math.floor((dateFuture - dateNow) / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  hours -= days * 24;
  minutes = minutes - days * 24 * 60 - hours * 60;
  seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
  const first = days > 0 ? days * 24 + hours : hours;

  return `${fillZero(first)}${fillZero(minutes)}${fillZero(seconds)}`;
};

/**
 * @param {Date} time
 * @returns {string}
 */
const formatTime = time =>
  `${fillZero(time.getHours())}${fillZero(time.getMinutes())}${fillZero(time.getSeconds())}`;

const Time = ({ options, time, mode, withEndFireworks }) => {
  const [value, setValue] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [fireworks, setFireworks] = useState(false);
  const prevIntValue = useRef(0);
  const styles = extractElementStyles(options);
  const { showDays, isVisible, isAnimated } = options;
  const duration = isAnimated ? ANIMATION_DURATION : 0;

  useEffect(() => {
    clearInterval(intervalId);

    if (mode === 'COUNTER') {
      const id = setInterval(() => {
        const val = getDiff(time);

        // eslint-disable-next-line eqeqeq
        if (val == 0) {
          clearInterval(intervalId);
        }

        setValue(val);
      }, ANIMATION_DURATION);

      setIntervalId(id);
    } else {
      const val = formatTime(time);
      setValue(val);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [time, mode]);

  useEffect(() => {
    const intValue = parseInt(`${value}`, 10);

    if (intValue === 0 && prevIntValue.current > 0) {
      setFireworks(true);
    } else {
      setFireworks(false);
    }

    prevIntValue.current = intValue;
  }, [value]);

  let currentValue = value;
  let days = 0;

  if (showDays) {
    const hours = value.toString().substring(0, value.length - 4);
    const restTime = value.toString().substring(value.length - 4, value.length);
    currentValue = `${fillZero(hours % 24)}${restTime}`;
    days = Math.floor(hours / 24);
  }

  if (isVisible) {
    return (
      <>
        <OdometerWrapper isAnimated={isAnimated} style={{ ...styles }}>
          {showDays && (
            <DayOdometerWrapper>
              <Odometer format="d" minIntegerLen={1} duration={duration} value={days} />
              <Days days={days} />
            </DayOdometerWrapper>
          )}
          <Odometer format="ddd:dd:dd" duration={duration} value={currentValue} />
        </OdometerWrapper>
        {withEndFireworks && <EndFireworks running={fireworks} />}
      </>
    );
  }

  return null;
};

Time.propTypes = {
  options: PropTypes.instanceOf(Object).isRequired,
  time: PropTypes.instanceOf(Date).isRequired,
  mode: PropTypes.oneOf(['CLOCK', 'COUNTER']).isRequired,
  withEndFireworks: PropTypes.bool,
};

Time.defaultProps = {
  withEndFireworks: false,
};

export default Time;
